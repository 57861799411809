@import "~bootstrap/dist/css/bootstrap.min";
@import "~bootstrap-icons/font/bootstrap-icons";

@import "./style/stylesheet.scss";

@keyframes dots {
  0% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
}

body {
  margin: 0;
  display: flex;
  flex-direction: column;
  background-color: #f7f8f9;
  font-family: "Helvetica Neue";
  font-style: normal;
  // font-weight: 500;
  // font-size: 16px;
  // line-height: 16px;
}

.square-img {
  position: relative;
  width: 100%;
  overflow: hidden;

  &-wrp {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    // display: none;
  }
}

.react-datepicker-wrapper {
  width: auto;
}

.modal.fade.show {
  display: block !important;
}

.bg-gr {
  background: #f7f8f9 !important;
  color: #212529;
}
.bg-gray {
  background-color: #f2f2f2;
  hr {
    color: #dee2e6;
    opacity: 1;
  }
}

a {
  text-decoration: none;
  color: #275382;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.ce-block__content {
  max-width: initial;
}
.codex-editor {
  border: #eee 1px solid;
  padding: 10px 30px;
  border-radius: 2px;
}

.ce-toolbar__content {
  max-width: 93%;
}

.modal-tool {
  .img-preview {
    width: 250px;
    margin: auto;

    overflow: hidden;

    &:before {
      content: "";
      padding-top: 100%;
      float: left;
    }
  }
}
.modal-dialog {
  .modal-content {
    .modal-img {
      max-height: 80vh;
    }
  }
}

.container-nav__catalog,
.main-page__container {
  margin-bottom: 50px;
}

.preview-photo {
  opacity: 0;
  visibility: hidden;
  max-height: 0px;
  overflow-x: hidden;
  transition: all 0.3s linear;

  &.active {
    opacity: 1;
    visibility: visible;
    max-height: 1000px;
    overflow-x: auto;

    .preview-photo__elem {
      margin-bottom: 25px;
    }
  }
}

.preview-overlay {
  top: 0;
  left: 0;
  position: fixed;
  height: 100vh;
  width: 100vw;
  visibility: hidden;
  opacity: 0;
  display: none;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 99;

  &.active {
    display: flex;
    opacity: 1;
    visibility: visible;
  }
}

.chars-sortable {
  & > div {
    display: flex;
    margin-bottom: 5px;
  }
}

.deactive {
  pointer-events: none;
}

.loader {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;

  // animate dot dot dot
  & > span:after {
    content: '.';
    animation: dots 1.5s infinite;
  }
}
