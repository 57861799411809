@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeueBoldCondensed.eot");
  src: local("Helvetica Neue Condensed Bold"),
    local("HelveticaNeueBoldCondensed"),
    url("HelveticaNeueBoldCondensed.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeueBoldCondensed.woff2") format("woff2"),
    url("HelveticaNeueBoldCondensed.woff") format("woff"),
    url("HelveticaNeueBoldCondensed.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeueBlackCondensed.eot");
  src: local("Helvetica Neue Condensed Black"),
    local("HelveticaNeueBlackCondensed"),
    url("HelveticaNeueBlackCondensed.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeueBlackCondensed.woff2") format("woff2"),
    url("HelveticaNeueBlackCondensed.woff") format("woff"),
    url("HelveticaNeueBlackCondensed.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeueCyr-Bold.eot");
  src: local("HelveticaNeueCyr-Bold"),
    url("HelveticaNeueCyr-Bold.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeueCyr-Bold.woff2") format("woff2"),
    url("HelveticaNeueCyr-Bold.woff") format("woff"),
    url("HelveticaNeueCyr-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeueCyr-Light.eot");
  src: local("HelveticaNeueCyr-Light"),
    url("HelveticaNeueCyr-Light.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeueCyr-Light.woff2") format("woff2"),
    url("HelveticaNeueCyr-Light.woff") format("woff"),
    url("HelveticaNeueCyr-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeueCyr-Medium.eot");
  src: local("HelveticaNeueCyr-Medium"),
    url("HelveticaNeueCyr-Medium.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeueCyr-Medium.woff2") format("woff2"),
    url("HelveticaNeueCyr-Medium.woff") format("woff"),
    url("HelveticaNeueCyr-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeueCyr-MediumItalic.eot");
  src: local("HelveticaNeueCyr-MediumItalic"),
    url("HelveticaNeueCyr-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeueCyr-MediumItalic.woff2") format("woff2"),
    url("HelveticaNeueCyr-MediumItalic.woff") format("woff"),
    url("HelveticaNeueCyr-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeueCyr-LightItalic.eot");
  src: local("HelveticaNeueCyr-LightItalic"),
    url("HelveticaNeueCyr-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeueCyr-LightItalic.woff2") format("woff2"),
    url("HelveticaNeueCyr-LightItalic.woff") format("woff"),
    url("HelveticaNeueCyr-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeueCyr-HeavyItalic.eot");
  src: local("HelveticaNeueCyr-HeavyItalic"),
    url("HelveticaNeueCyr-HeavyItalic.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeueCyr-HeavyItalic.woff2") format("woff2"),
    url("HelveticaNeueCyr-HeavyItalic.woff") format("woff"),
    url("HelveticaNeueCyr-HeavyItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeueCyr-Black.eot");
  src: local("HelveticaNeueCyr-Black"),
    url("HelveticaNeueCyr-Black.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeueCyr-Black.woff2") format("woff2"),
    url("HelveticaNeueCyr-Black.woff") format("woff"),
    url("HelveticaNeueCyr-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeueCyr-Heavy.eot");
  src: local("HelveticaNeueCyr-Heavy"),
    url("HelveticaNeueCyr-Heavy.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeueCyr-Heavy.woff2") format("woff2"),
    url("HelveticaNeueCyr-Heavy.woff") format("woff"),
    url("HelveticaNeueCyr-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeueCyr-Italic.eot");
  src: local("HelveticaNeueCyr-Italic"),
    url("HelveticaNeueCyr-Italic.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeueCyr-Italic.woff2") format("woff2"),
    url("HelveticaNeueCyr-Italic.woff") format("woff"),
    url("HelveticaNeueCyr-Italic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeueCyr-Roman.eot");
  src: local("HelveticaNeueCyr-Roman"),
    url("HelveticaNeueCyr-Roman.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeueCyr-Roman.woff2") format("woff2"),
    url("HelveticaNeueCyr-Roman.woff") format("woff"),
    url("HelveticaNeueCyr-Roman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeueCyr-BlackItalic.eot");
  src: local("HelveticaNeueCyr-BlackItalic"),
    url("HelveticaNeueCyr-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeueCyr-BlackItalic.woff2") format("woff2"),
    url("HelveticaNeueCyr-BlackItalic.woff") format("woff"),
    url("HelveticaNeueCyr-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeueCyr-UltraLightItalic.eot");
  src: local("HelveticaNeueCyr-UltraLightItalic"),
    url("HelveticaNeueCyr-UltraLightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("HelveticaNeueCyr-UltraLightItalic.woff2") format("woff2"),
    url("HelveticaNeueCyr-UltraLightItalic.woff") format("woff"),
    url("HelveticaNeueCyr-UltraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeueCyr-BoldItalic.eot");
  src: local("HelveticaNeueCyr-BoldItalic"),
    url("HelveticaNeueCyr-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeueCyr-BoldItalic.woff2") format("woff2"),
    url("HelveticaNeueCyr-BoldItalic.woff") format("woff"),
    url("HelveticaNeueCyr-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeueCyr-ThinItalic.eot");
  src: local("HelveticaNeueCyr-ThinItalic"),
    url("HelveticaNeueCyr-ThinItalic.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeueCyr-ThinItalic.woff2") format("woff2"),
    url("HelveticaNeueCyr-ThinItalic.woff") format("woff"),
    url("HelveticaNeueCyr-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeueCyr-UltraLight.eot");
  src: local("HelveticaNeueCyr-UltraLight"),
    url("HelveticaNeueCyr-UltraLight.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeueCyr-UltraLight.woff2") format("woff2"),
    url("HelveticaNeueCyr-UltraLight.woff") format("woff"),
    url("HelveticaNeueCyr-UltraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeueCyr-Thin.eot");
  src: local("HelveticaNeueCyr-Thin"),
    url("HelveticaNeueCyr-Thin.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeueCyr-Thin.woff2") format("woff2"),
    url("HelveticaNeueCyr-Thin.woff") format("woff"),
    url("HelveticaNeueCyr-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
